import React from "react"
import PropTypes from "prop-types"

class SensorDataClass extends React.Component {
  render () {
    return (
      <React.Fragment>
        Type: {this.props.type}
        Value: {this.props.value}
        Unit: {this.props.unit}
      </React.Fragment>
    );
  }
}

SensorDataClass.propTypes = {
  type: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.string
};
export default SensorDataClass
