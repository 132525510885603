import React from 'react';
import PropTypes from 'prop-types';
import styles from './SensorData.module.scss';

const SensorData = ({type, value, unit}) => {

  //TODO: set data value text color bases on range

    return (
      <div className={styles.sensorDataComponent}>
        <div className={styles.sensorDataType}>{type}</div>
        <div className={styles.sensorDataValue}>{value}</div>
        <div className={styles.sensorDataUnit}>{unit}</div>
      </div>
    );
}

SensorData.propTypes = {
  type: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.string
}

export default SensorData;  