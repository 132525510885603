import React, { useEffect, useState }from 'react';
//import AirCatResponsiveLine from './AirCatResponsiveLine';
import styles from './GraphTabs.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import { mergeClasses } from '@material-ui/styles';



const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


const GraphTabs = (sensorList) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sensorData, setSensorData] = useState([]);


    // useEffect(() => {
    //   fetch("/data")
    // })


  console.log("Graph Tab");
  console.log(sensorList);
  console.log(sensorList[0].sensor_datum);

  
  let data3 = sensorList[0].sensor_datum.map(d => 
    ({x: d.created_at, y: d.value })).slice(0,24);

    //new Date(d.created_at).getHours()
  //data3 = JSON.stringify(data3);

  // let data2 = [
  //   {
  //     "id": "temp1",
  //     "color": "hsl(253, 70%, 50%)",
  //     "data": data3
  //   }
  // ]

  let unit = sensorList[0].unit;



  let data2 = [
    {
      "id": "temp1",
      "color": "hsl(253, 70%, 50%)",
      "data": [
        {
          "x": 1,
          "y": 14
        },
        {
          "x": 2,
          "y": 16
        },
        {
          "x": 3,
          "y": 18
        },
        {
          "x": 4,
          "y": 19
        },
        {
          "x": 5,
          "y": 21
        },
        {
          "x": 6,
          "y": 23
        },
        {
          "x": 7,
          "y": 26
        },
        {
          "x": 8,
          "y": 24
        },
        {
          "x": 9,
          "y": 22
        },
        {
          "x": 10,
          "y": 21
        },
        {
          "x": 11,
          "y": 20
        },
        {
          "x": 12,
          "y": 18
        }
      ]


    }
  ]


  console.log(unit);
  console.log(data2);




  let data = [
    {
      "id": "japan",
      "color": "hsl(126, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 10
        },
        {
          "x": "helicopter",
          "y": 12
        },
        {
          "x": "boat",
          "y": 128
        },
        {
          "x": "train",
          "y": 99
        },
        {
          "x": "subway",
          "y": 31
        },
        {
          "x": "bus",
          "y": 17
        },
        {
          "x": "car",
          "y": 116
        },
        {
          "x": "moto",
          "y": 212
        },
        {
          "x": "bicycle",
          "y": 162
        },
        {
          "x": "horse",
          "y": 92
        },
        {
          "x": "skateboard",
          "y": 105
        },
        {
          "x": "others",
          "y": 254
        }
      ]
    },
    {
      "id": "france",
      "color": "hsl(281, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 93
        },
        {
          "x": "helicopter",
          "y": 80
        },
        {
          "x": "boat",
          "y": 188
        },
        {
          "x": "train",
          "y": 100
        },
        {
          "x": "subway",
          "y": 140
        },
        {
          "x": "bus",
          "y": 281
        },
        {
          "x": "car",
          "y": 128
        },
        {
          "x": "moto",
          "y": 70
        },
        {
          "x": "bicycle",
          "y": 65
        },
        {
          "x": "horse",
          "y": 49
        },
        {
          "x": "skateboard",
          "y": 246
        },
        {
          "x": "others",
          "y": 289
        }
      ]
    },
    {
      "id": "us",
      "color": "hsl(197, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 210
        },
        {
          "x": "helicopter",
          "y": 36
        },
        {
          "x": "boat",
          "y": 44
        },
        {
          "x": "train",
          "y": 284
        },
        {
          "x": "subway",
          "y": 102
        },
        {
          "x": "bus",
          "y": 235
        },
        {
          "x": "car",
          "y": 131
        },
        {
          "x": "moto",
          "y": 165
        },
        {
          "x": "bicycle",
          "y": 252
        },
        {
          "x": "horse",
          "y": 278
        },
        {
          "x": "skateboard",
          "y": 85
        },
        {
          "x": "others",
          "y": 259
        }
      ]
    },
    {
      "id": "germany",
      "color": "hsl(253, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 275
        },
        {
          "x": "helicopter",
          "y": 39
        },
        {
          "x": "boat",
          "y": 150
        },
        {
          "x": "train",
          "y": 238
        },
        {
          "x": "subway",
          "y": 3
        },
        {
          "x": "bus",
          "y": 271
        },
        {
          "x": "car",
          "y": 269
        },
        {
          "x": "moto",
          "y": 214
        },
        {
          "x": "bicycle",
          "y": 56
        },
        {
          "x": "horse",
          "y": 281
        },
        {
          "x": "skateboard",
          "y": 211
        },
        {
          "x": "others",
          "y": 28
        }
      ]
    },
    {
      "id": "norway",
      "color": "hsl(200, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 239
        },
        {
          "x": "helicopter",
          "y": 80
        },
        {
          "x": "boat",
          "y": 33
        },
        {
          "x": "train",
          "y": 55
        },
        {
          "x": "subway",
          "y": 146
        },
        {
          "x": "bus",
          "y": 36
        },
        {
          "x": "car",
          "y": 40
        },
        {
          "x": "moto",
          "y": 96
        },
        {
          "x": "bicycle",
          "y": 132
        },
        {
          "x": "horse",
          "y": 211
        },
        {
          "x": "skateboard",
          "y": 284
        },
        {
          "x": "others",
          "y": 103
        }
      ]
    }
  ];

  console.log(data);
  //TODO: 
  // build tabs, one for each sensor type
  // create graphs
  // ranges, DB, hardcoded?
  // ajax requests here to get graph data?
  //



  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createTabs = () => {
    const retAr = [];
    Object.keys(sensorList).forEach(key => {
      console.log(sensorList[key]);
      retAr.push(<Tab key={key} label={sensorList[key].name}></Tab>)
    });

    return retAr;
  }

  

  return (
    <div className={styles.graphContainer}>Graph Tabs
      <br />

      <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {/* <Tab label="Item One" />
        <Tab label="Item Two" />
        <Tab label="Item Three" /> */}

        {createTabs()}

      </Tabs>
      </Paper>

      {/* <AirCatResponsiveLine data={data2} unit={unit}></AirCatResponsiveLine> */}


    </div>






  );


}


export default GraphTabs