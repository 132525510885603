// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import React from "react";
import { ResponsiveLine } from "@nivo/line";
//import { timeFormat } from 'd3-time-format'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

//2021-10-01T13:04:47Z

const AirCatResponsiveLine = ({ data, unit /* see data tab */ }) => {
  console.log("load AirCatResponsiveLine");
  console.log(data);
  console.log(unit);

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{
        type: "time",
        format: "%Y-%m-%dT%H:%M:%S",
        useUTC: false,
        precision: "second",
      }}
      xFormat="time:%Y-%m-%dT%H:%M:%S"
      
      //old below
      // xScale={{
      //     format: "%Y-%m-%dT%H:%M:%S%Z",
      //     type: "time" }}

      //xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
      //xFormat="time:%F %T"

      // xScale={{ type: 'point' }}
      // xScale={{
      //     type: 'time',
      //     format: '%Y-%m-%d',
      //     useUTC: false,
      //     precision: 'hour',
      // }}
      // xFormat="time:%Y-%m-%d %H:%M:%S%Z"
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
    
      axisBottom={{
        format: "%I:%M",
        tickValues: "every 30 minutes",
        legend: "time scale",
        legendOffset: -12,
      }}
      // axisBottom={{
      //     tickValues: "every 1 hour",
      //     tickSize: 5,
      //     tickPadding: 5,
      //     tickRotation: 0,
      //     // format: "%H",
      //     legend: "Time",
      //     legendOffset: 36,
      //     legendPosition: "middle"
      //   }}

      // axisBottom={{
      //     orient: 'bottom',
      //     tickSize: 5,
      //     tickPadding: 5,
      //     tickRotation: 0,
      //     legend: 'time',
      //     legendOffset: 36,
      //     legendPosition: 'middle'
      // }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: unit,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      colors={{ scheme: "nivo" }}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      // legends={[
      //     {
      //         anchor: 'bottom-right',
      //         direction: 'column',
      //         justify: false,
      //         translateX: 100,
      //         translateY: 0,
      //         itemsSpacing: 0,
      //         itemDirection: 'left-to-right',
      //         itemWidth: 80,
      //         itemHeight: 20,
      //         itemOpacity: 0.75,
      //         symbolSize: 12,
      //         symbolShape: 'circle',
      //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
      //         effects: [
      //             {
      //                 on: 'hover',
      //                 style: {
      //                     itemBackground: 'rgba(0, 0, 0, .03)',
      //                     itemOpacity: 1
      //                 }
      //             }
      //         ]
      //     }
      // ]}
    />
  );
};

export default AirCatResponsiveLine;
