import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './GraphTabs.module.scss';
import GraphTab from './GraphTab.jsx';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return ( 

    //console.log(value + "==")
    <div
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function GraphTabs2(sensorList) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
      console.log("handle change: " + newValue);
    setValue(newValue);
  };

  const createTabs = () => {
      const retArr = [];
      Object.keys(sensorList).forEach(key => {

        console.log(key);
        //if (key == 0) {

          console.log(sensorList[key]);
          //retAr.push(<Tab key={key} label={sensorList[key].name}></Tab>);
          retArr.push(<Tab label={sensorList[key].name} key={key} {...a11yProps(key)} />);
      //  }
      });
  
      console.log(retArr);
      return retArr;
  }

  const createTabPanels = () => {
      const retArr = [];
      Object.keys(sensorList).forEach(key => {
         
       // if (key == 0) {
          console.log("Tab Panels: " + key + " " + sensorList[key].name);
            console.log(sensorList[key]);

          retArr.push(
          <TabPanel value={value} key={key + 10} index={key}>
              {sensorList[key].name}
              <br />
              <div className={styles.graphContainer}>
                  <GraphTab sensor={sensorList[key]}></GraphTab>
              </div>
          </TabPanel>);
     //  }
      });

      return retArr;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {createTabs()}
        </Tabs>
      </Box>
          {createTabPanels()}
    </Box>
  );
}
