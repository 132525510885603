//Responsive mobile header hamburger menu
document.addEventListener("DOMContentLoaded", function(event) {
    console.log("application.js");

    const button = document.querySelector("#menu-button");
    const menu = document.querySelector("#menu");
    
    button.addEventListener("click", () => {
      menu.classList.toggle("hidden");
    });

});