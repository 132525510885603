import React, { useEffect, useState } from 'react';
import AirCatResponsiveLine from './AirCatResponsiveLine';
import styles from './GraphTabs.module.scss';

const GraphTab = (props) => {

  console.log("Load Graph Tab");

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  console.log(props.sensor);

  useEffect(() => {
    const url = `/sensor/${props.sensor.id}/data`
    console.log("load data: " + url);

    fetch(url)
      .then(response => response.json())
      .then((result) => {

        let graphData = [
          {
            id: "temp_1",
            color: "hsl(253, 70%, 50%)",
            data: result
          }];

        console.log("GD Result");
        console.log(graphData);

        setData(graphData);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err.message)
      });

  }, []);

  if (isLoaded) {
    return (
      <div className={styles.graphContainer}>
        {/* <div>Hello GraphTab</div> */}
        <AirCatResponsiveLine data={data} unit={props.sensor.unit} />
      </div>
    );

  } else {
    return (<div>Graph loading .... </div>);
  }
}

export default GraphTab